import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import dateFormat from "dateformat";
import Row from "react-bootstrap/Row";
import thousandformat from "../helpers/thousanddelimiter";
import Button from "react-bootstrap/Button";

/* If readOnly, 
the data will be counted first before shown need some new methods to handle that */

const MrpTable = ({
  listMaterials, //Json from backend will be here
  idx, //index of the data, used for updating state in parent
  summary, //If readOnly, the data will be counted first before shown need some new methods to handle that
  type, //Can be plant or mixer
  onChange, //To handle change and save data to backend, still no idea
  readOnly,
  handleSelection,
}) => {
  //STYLES HERE
  const headerStyle = {
    backgroundColor: summary ? "#28A745" : "#17A2B8",
    color: "white",
  };
  const disabledStyle = { backgroundColor: "#DBDBDB" };

  useEffect(() => {
    if (!summary) {
      if (listMaterials.kg_per_batch === null) {
        listMaterials = { ...listMaterials, kg_per_batch: "0" };
      }
      if (listMaterials.hours_per_batch === null) {
        listMaterials = { ...listMaterials, hours_per_batch: "0" };
      }

      if (listMaterials.kg_per_batch_p1 === null) {
        listMaterials = { ...listMaterials, kg_per_batch_p1: "0" };
      }
      if (listMaterials.hours_per_batch_p1 === null) {
        listMaterials = { ...listMaterials, hours_per_batch_p1: "0" };
      }

      if (listMaterials.kg_per_batch_p2 === null) {
        listMaterials = { ...listMaterials, kg_per_batch_p2: "0" };
      }
      if (listMaterials.hours_per_batch_p2 === null) {
        listMaterials = { ...listMaterials, hours_per_batch_p2: "0" };
      }
      calcBatchProdHrs();
    }
  }, []);

  const renderHeader = () => {
    return listMaterials.data.map((item, kkk) => (
      <th colSpan={type === "plant" ? "3" : "1"} style={headerStyle}>
        {dateFormat(item.month, "mmm-yy")}

        {!summary && type == "mixer" && (
          <>
            <br />
            {item.bom_version && `Version ${item.bom_version} `}
            <Button
              variant="default"
              onClick={() =>
                handleSelection(listMaterials.material_code, "BLENDING", kkk)
              }
              className="btn btn-xs"
              key={`bpb${kkk}`}
            >
              <i className="fas fa-project-diagram" />
            </Button>
          </>
        )}
      </th>
    ));
  };

  const renderSubheader = () => {
    if (type === "plant") {
      return listMaterials.data.map((item, kkk) => [
        <th>
          Plant 1
          {!summary && (
            <>
              <br />
              {item.plant[0].bom_version &&
                `Version ${item.plant[0].bom_version} `}
              <Button
                variant="default"
                onClick={() =>
                  handleSelection(listMaterials.material_code, "PLANT_1", kkk)
                }
                className="btn btn-xs"
                key={`bp1${kkk}`}
              >
                <i className="fas fa-project-diagram" />
              </Button>
            </>
          )}
        </th>,
        <th>
          Plant 2
          {!summary && (
            <>
              <br />
              {item.plant[1].bom_version &&
                `Version ${item.plant[1].bom_version} `}
              <Button
                variant="default"
                onClick={() =>
                  handleSelection(listMaterials.material_code, "PLANT_2", kkk)
                }
                className="btn btn-xs"
                key={`bp2${kkk}`}
              >
                <i className="fas fa-project-diagram" />
              </Button>
            </>
          )}
        </th>,
        <th>Total</th>,
      ]);
    }
    if (type === "mixer") {
      return listMaterials.data.map((item, kkk) => [
        <th style={disabledStyle}></th>,
      ]);
    }
  };

  const renderQuantity = () => {
    if (type === "plant") {
      let capacity = listMaterials?.machine_capacity
        ? listMaterials.machine_capacity
        : 0;
      let total_capacity = Number(capacity) * 24 * 30;
      return listMaterials.data.map((item, idx) => {
        let forecast = item?.forecast ? item.forecast : 0;
        let balance = listMaterials?.balance ? listMaterials.balance : 0;
        if (idx === 0) {
          forecast = forecast - balance;
        }

        if (!item.plant[0].qty && !item.plant[1].qty && forecast > 0) {
          if (listMaterials.facility_number === "1") {
            if (forecast <= total_capacity) {
              item.plant[0].qty = forecast;
            } else {
              item.plant[0].qty = total_capacity;
              item.plant[1].qty = forecast - total_capacity;
            }
          }
          if (listMaterials.facility_number === "2") {
            if (forecast <= total_capacity) {
              item.plant[1].qty = forecast;
            } else {
              item.plant[1].qty = total_capacity;
              item.plant[0].qty = forecast - total_capacity;
            }
          }
        }

        return readOnly ? (
          <>
            <td>
              {thousandformat(
                isNaN(item.plant[0].qty) ? "" : item.plant[0].qty,
                ".",
                ".",
                ","
              )}
            </td>
            <td>
              {thousandformat(
                isNaN(item.plant[1].qty) ? "" : item.plant[1].qty,
                ".",
                ".",
                ","
              )}
            </td>
            <td>
              {item.total_qty === null
                ? "0"
                : thousandformat(
                    isNaN(item.total_qty) ? "" : item.total_qty,
                    ".",
                    ".",
                    ","
                  )}
            </td>
          </>
        ) : (
          <>
            <td>
              <div className="form-group">
                <input
                  type="number"
                  step="1000"
                  className="form-control form-control-sm Input-number-hide-step"
                  required="required"
                  defaultValue={
                    isNaN(item.plant[0].qty) ? "" : item.plant[0].qty
                  }
                  onChange={handleChangeTable(idx, 0)}
                  style={{ fontSize: 12 }}
                  min="0"
                />
              </div>
            </td>
            <td>
              <div className="form-group">
                <input
                  type="number"
                  step="1000"
                  className="form-control form-control-sm Input-number-hide-step"
                  required="required"
                  defaultValue={
                    isNaN(item.plant[1].qty) ? "" : item.plant[1].qty
                  }
                  onChange={handleChangeTable(idx, 1)}
                  style={{ fontSize: 12 }}
                  min="0"
                />
              </div>
            </td>
            <td>
              {item.total_qty === null
                ? "0"
                : thousandformat(
                    isNaN(item.total_qty) ? "" : item.total_qty,
                    ".",
                    ".",
                    ","
                  )}
            </td>
          </>
        );
      });

      // item.plant.map((itemp)=>{

      //   if(listMaterials.facility_number === itemp.facility_number){
      //     if(forecast <= total_capacity){
      //       itemp.qty
      //     }
      //   }
      //   if(listMaterials.facility_number === "2"){

      //   }
      // })
    }
    if (type === "mixer") {
      return listMaterials.data.map((item, idx) => [
        readOnly ? (
          <td>
            {thousandformat(
              isNaN(item.total_qty) ? "" : item.total_qty,
              ".",
              ".",
              ","
            )}
          </td>
        ) : (
          <td>
            <div className="form-group">
              <input
                type="number"
                step="1000"
                className="form-control form-control-sm"
                required="required"
                defaultValue={item.total_qty}
                onChange={handleChangeTable(idx, 0)}
                style={{ fontSize: 12 }}
                min="0"
              />
            </div>
          </td>
        ),
      ]);
    }
  };

  const renderForecast = () => {
    return listMaterials.data.map((item) => {
      let forecast = item.forecast === null ? "0" : parseInt(item.forecast);
      return (
        <td colSpan={type === "plant" ? "3" : "1"}>
          {isNaN(forecast)
            ? "0"
            : thousandformat(forecast.toString(), ".", ".", ",")}
        </td>
      );
    });
  };
  const renderForecastRnd = () => {
    return listMaterials.data.map((item) => {
      let forecast_rnd = parseInt(item.forecast_rnd);
      return (
        <td colSpan={type === "plant" ? "3" : "1"}>
          {isNaN(forecast_rnd)
            ? ""
            : thousandformat(forecast_rnd.toString(), ".", ".", ",")}
        </td>
      );
    });
  };

  const renderBatch = () => {
    if (type === "plant") {
      return listMaterials.data.map((item) => [
        <td>{isNaN(item.plant[0].batch) ? "" : item.plant[0].batch}</td>,
        <td>{isNaN(item.plant[1].batch) ? "" : item.plant[1].batch}</td>,
        <td>{isNaN(item.total_batch) ? "" : item.total_batch}</td>,
      ]);
    }
    if (type === "mixer") {
      return listMaterials.data.map((item) => [
        <td>{isNaN(item.total_batch) ? "" : item.total_batch}</td>,
      ]);
    }
  };

  const renderHeaderKgandHours = () => {
    if (type === "plant") {
      return readOnly ? null : (
        <>
          <Row>
            {" "}
            <div className="form-group" style={{ display: "none" }}>
              <label htmlFor="price">&nbsp;</label>
              <br></br>
              <label htmlFor="price">Plant 1</label>&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div className="form-group" style={{ display: "none" }}>
              <label htmlFor="price">Kg Per Batch</label>
              <input
                type="number"
                step="1"
                className="form-control form-control-sm"
                name="kgPerBatch"
                placeholder="Kg Per Batch"
                required="required"
                defaultValue={listMaterials.kg_per_batch_p1}
                onChange={handleChangeInput("kg", "p1")}
                min="0"
              />
            </div>
            <div className="form-group" style={{ display: "none" }}>
              <label htmlFor="price">Hours Per Batch</label>
              <input
                type="number"
                step="0.01"
                className="form-control form-control-sm"
                name="price"
                placeholder="Hrs Per Batch"
                required="required"
                defaultValue={listMaterials.hours_per_batch_p1}
                onChange={handleChangeInput("hrs", "p1")}
                min="0"
              />
            </div>
          </Row>
          <Row>
            {" "}
            <div className="form-group" style={{ display: "none" }}>
              <label htmlFor="price">&nbsp;</label>
              <label htmlFor="price">Plant 2</label>&nbsp;&nbsp;&nbsp;
            </div>
            <div className="form-group" style={{ display: "none" }}>
              <input
                type="number"
                step="1"
                className="form-control form-control-sm"
                name="kgPerBatch"
                placeholder="Kg Per Batch"
                required="required"
                defaultValue={listMaterials.kg_per_batch_p2}
                onChange={handleChangeInput("kg", "p2")}
                min="0"
              />
            </div>
            <div className="form-group" style={{ display: "none" }}>
              <input
                type="number"
                step="0.01"
                className="form-control form-control-sm"
                name="price"
                placeholder="Hrs Per Batch"
                required="required"
                defaultValue={listMaterials.hours_per_batch_p2}
                onChange={handleChangeInput("hrs", "p2")}
                min="0"
              />
            </div>
          </Row>
        </>
      );
    }
    if (type === "mixer") {
      return readOnly ? null : (
        <Row>
          <div className="form-group" style={{ display: "none" }}>
            <label htmlFor="price">Kg Per Batch</label>
            <input
              type="number"
              step="1"
              className="form-control form-control-sm"
              name="kgPerBatch"
              placeholder="Kg Per Batch"
              required="required"
              defaultValue={listMaterials.kg_per_batch}
              onChange={handleChangeInput("kg", "mixer")}
              min="0"
            />
          </div>
          <div className="form-group" style={{ display: "none" }}>
            <label htmlFor="price">Hours Per Batch</label>
            <input
              type="number"
              step="0.01"
              className="form-control form-control-sm"
              name="price"
              placeholder="Hrs Per Batch"
              required="required"
              defaultValue={listMaterials.hours_per_batch}
              onChange={handleChangeInput("hrs", "mixer")}
              min="0"
            />
          </div>
        </Row>
      );
    }
  };

  const renderProdHours = () => {
    if (type === "plant") {
      return listMaterials.data.map((item) => [
        <td>
          {isNaN(item.plant[0].prod_hours) ? "" : item.plant[0].prod_hours}
        </td>,
        <td>
          {isNaN(item.plant[1].prod_hours) ? "" : item.plant[1].prod_hours}
        </td>,
        <td>{isNaN(item.total_prod_hours) ? "" : item.total_prod_hours}</td>,
      ]);
    }
    if (type === "mixer") {
      return listMaterials.data.map((item) => [
        <td>{isNaN(item.total_prod_hours) ? "" : item.total_prod_hours}</td>,
      ]);
    }
  };

  const handleChangeTable = (itemIdx, plantIdx) => (e) => {
    if (type === "plant") {
      let newValue = e.target.value;
      //copy array value from state
      let newData = [...listMaterials.data];

      //updating qty in plant (need refractor)
      newData[itemIdx].plant[plantIdx] = {
        ...newData[itemIdx].plant[plantIdx],
        qty: newValue ? newValue : "0",
      };

      //calculating total qty
      let qtyPlant0 =
        newData[itemIdx].plant[0].qty === null
          ? "0"
          : newData[itemIdx].plant[0].qty;
      let qtyPlant1 =
        newData[itemIdx].plant[1].qty === null
          ? "0"
          : newData[itemIdx].plant[1].qty;
      let totalQty = 0;

      if (qtyPlant0 !== null && qtyPlant1 !== null) {
        totalQty = parseInt(qtyPlant0) + parseInt(qtyPlant1);
      }

      //updating total qty
      newData[itemIdx] = {
        ...newData[itemIdx],
        total_qty: totalQty.toString(),
      };

      //updating listMaterials
      listMaterials = { ...listMaterials, data: newData };
    }

    if (type === "mixer") {
      let newValue = e.target.value;
      //copy array value from state
      let newData = [...listMaterials.data];

      //updating qty
      newData[itemIdx] = {
        ...newData[itemIdx],
        total_qty: newValue ? newValue : "0",
      };

      //updating listMaterials
      listMaterials = { ...listMaterials, data: newData };
    }

    //calulating
    calcBatchProdHrs();
  };

  const handleChangeInput = (type, prd_facility) => (e) => {
    let newValue = e.target.value;
    if (type === "kg" && prd_facility === "p1") {
      listMaterials = {
        ...listMaterials,
        kg_per_batch: newValue,
        kg_per_batch_p1: newValue,
      };
    }
    if (type === "hrs" && prd_facility === "p1") {
      listMaterials = {
        ...listMaterials,
        hours_per_batch: newValue,
        hours_per_batch_p1: newValue,
      };
    }

    if (type === "kg" && prd_facility === "p2") {
      listMaterials = { ...listMaterials, kg_per_batch_p2: newValue };
    }
    if (type === "hrs" && prd_facility === "p2") {
      listMaterials = { ...listMaterials, hours_per_batch_p2: newValue };
    }

    if (type === "kg" && prd_facility === "mixer") {
      listMaterials = { ...listMaterials, kg_per_batch: newValue };
    }
    if (type === "hrs" && prd_facility === "mixer") {
      listMaterials = { ...listMaterials, hours_per_batch: newValue };
    }
    calcBatchProdHrs();
  };

  const calcBatchProdHrs = () => {
    //copy array value to new var
    let newData = [...listMaterials.data];

    if (type === "plant") {
      //looping to update data on each date
      newData.map((item, idx) => {
        let newPlant = [...item.plant];

        //calculating
        let qtyPlant0 = newPlant[0].qty === null ? "0" : newPlant[0].qty;
        let qtyPlant1 = newPlant[1].qty === null ? "0" : newPlant[1].qty;
        let kgPerBatch_p1 = listMaterials.kg_per_batch_p1 ?? 0;
        let hrsPerBatch_p1 = listMaterials.hours_per_batch_p1 ?? 0;
        let kgPerBatch_p2 = listMaterials.kg_per_batch_p2 ?? 0;
        let hrsPerBatch_p2 = listMaterials.hours_per_batch_p2 ?? 0;

        let batch0 = 0;
        let batch1 = 0;
        let prodHrs0 = 0;
        let prodHrs1 = 0;
        let totalBatch = 0;
        let totalProdHrs = 0;
        let totalQty = 0;

        if (qtyPlant0 !== null && kgPerBatch_p1 !== null) {
          //plant 1
          batch0 = !isFinite(Math.ceil(qtyPlant0 / kgPerBatch_p1))
            ? ""
            : Math.ceil(qtyPlant0 / kgPerBatch_p1);
        }
        if (qtyPlant1 !== null && kgPerBatch_p2 !== null) {
          //plant 2
          batch1 = !isFinite(Math.ceil(qtyPlant1 / kgPerBatch_p2))
            ? ""
            : Math.ceil(qtyPlant1 / kgPerBatch_p2);
        }

        if (batch0 !== null && hrsPerBatch_p1 !== null) {
          //plant 1
          prodHrs0 = !isFinite(batch0 * hrsPerBatch_p1)
            ? ""
            : batch0 * hrsPerBatch_p1;
        }
        if (batch1 !== null && hrsPerBatch_p2 !== null) {
          //plant 2
          prodHrs1 = !isFinite(batch1 * hrsPerBatch_p2)
            ? ""
            : batch1 * hrsPerBatch_p2;
        }

        if (qtyPlant0 !== null && qtyPlant1 !== null) {
          totalQty = parseInt(qtyPlant0) + parseInt(qtyPlant1);
        }

        totalBatch = batch0 + batch1;
        totalProdHrs = prodHrs0 + prodHrs1;

        //updating batch & prod hrs in each plant
        newPlant[0] = {
          ...newPlant[0],
          qty: qtyPlant0 ? qtyPlant0.toString() : 0,
          batch: batch0 ? batch0.toString() : 0,
          prod_hours: isNaN(parseInt(prodHrs0)) ? "" : prodHrs0.toFixed(1),
        };

        newPlant[1] = {
          ...newPlant[1],
          qty: qtyPlant1 ? qtyPlant1.toString() : 0,
          batch: batch1 ? batch1.toString() : 0,
          prod_hours: isNaN(parseInt(prodHrs1)) ? "" : prodHrs1.toFixed(1),
        };

        newData[idx] = { ...newData[idx], plant: newPlant };

        //updating total kg/batch
        newData[idx] = {
          ...newData[idx],
          total_batch: totalBatch.toString(),
          total_prod_hours: isNaN(parseInt(totalProdHrs))
            ? ""
            : totalProdHrs.toFixed(1),
          total_qty: totalQty.toString(),
        };

        //updating listMaterials
        listMaterials = {
          ...listMaterials,
          data: newData,
        };
      });
    }

    if (type === "mixer") {
      //looping to update data on each date
      newData.map((item, idx) => {
        //calculating
        let qty = item.total_qty === null ? "0" : item.total_qty;
        let kgPerBatch = listMaterials.kg_per_batch;
        let hrsPerBatch = listMaterials.hours_per_batch;

        let batch = 0;
        let prodHrs = 0;

        if (qty !== null && kgPerBatch !== null) {
          batch = !isFinite(Math.ceil(qty / kgPerBatch))
            ? ""
            : Math.ceil(qty / kgPerBatch);
        }

        if (batch !== null && hrsPerBatch !== null) {
          prodHrs = !isFinite(batch * hrsPerBatch) ? "" : batch * hrsPerBatch;
        }

        //updating
        newData[idx] = {
          ...newData[idx],
          total_batch: batch.toString(),
          total_prod_hours: prodHrs ? prodHrs.toFixed(1) : 0,
          total_qty: qty ? qty.toString() : 0,
        };

        //updating listMaterials
        listMaterials = {
          ...listMaterials,
          data: newData,
        };
      });
    }

    //send back to parent (MrpPlant/MrpMixer)
    onChange(listMaterials, idx);
  };

  return (
    <div key={listMaterials}>
      <Form>
        {renderHeaderKgandHours()}
        <Table
          bordered
          hover
          size="sm"
          style={{ textAlign: "center", justifyContent: "center" }}
        >
          <thead>
            <tr>
              <th width={"6%"}></th>
              {readOnly ? null : <th style={headerStyle}>Beginning Balance</th>}
              {renderHeader()}
            </tr>
            {type === "plant" ? (
              <tr>
                <th></th>
                {readOnly ? null : <th></th>}
                {renderSubheader()}
              </tr>
            ) : null}
          </thead>
          <tbody>
            <tr>
              <td>Quantity (kg)</td>
              {readOnly ? null : (
                <td>
                  {thousandformat(
                    isFinite(listMaterials.balance)
                      ? listMaterials.balance
                      : "",
                    ".",
                    ".",
                    ","
                  )}
                </td>
              )}
              {renderQuantity()}
            </tr>

            {readOnly ? null : (
              <tr>
                <td>Forecast Sales</td>
                {readOnly ? null : <td style={disabledStyle}></td>}
                {renderForecast()}
              </tr>
            )}
            {readOnly ? null : (
              <tr>
                <td>Forecast RND</td>
                {readOnly ? null : <td style={disabledStyle}></td>}
                {renderForecastRnd()}
              </tr>
            )}

            <tr>
              <td>Batch</td>
              {readOnly ? null : <td style={disabledStyle}></td>}
              {renderBatch()}
            </tr>
            <tr>
              <td>Prod. Hours</td>
              {readOnly ? null : <td style={disabledStyle}></td>}
              {renderProdHours()}
            </tr>
          </tbody>
        </Table>
      </Form>
    </div>
  );
};

MrpTable.propTypes = {
  listMaterials: PropTypes.object.isRequired,
};

export default MrpTable;
