import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";

import "react-datepicker/dist/react-datepicker.css";
import { startOfMonth, addMonths, subMonths } from "date-fns";

import { readCurrencyWithoutRP } from "../modules/CurrencyModule";
import {
  readMaterialByType,
  readMaterialUomByCode,
} from "../modules/MaterialModule";
import { readUom } from "../modules/UomModule";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import Select from "react-select";
import {
  readQsBeginningFgBalance,
  overwriteBeginningFgBalance,
  addBeginningFgBalance,
  addBeginningFgBalanceBulk,
  editBeginningFgBalance,
} from "../modules/BeginningFgBalanceModule";
import RemoteAll from "../components/RemoteAll";
import { Card, Tab, Tabs } from "react-bootstrap";

import XLSX from "xlsx";
import Worksheet from "../components/Worksheet";
import moment from "moment";

const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "csv"]
  .map((x) => `.${x}`)
  .join(",");
class BeginningFgBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      submitted: false,
      submitted_create: false,
      showModal: false,
      showSpotPriceOutlook: false,
      loading: false,
      readOnly: false,
      currencyreadOnly: false,
      isValidMaterial: true,
      isValidMaterialCreate: true,
      isValidVendor: true,
      isValidVendorCreate: true,
      isClearable: true,
      collection: {},
      select_items: [],
      select_items_material: [],
      select_items_uom: [],
      list_datatable: [],
      errors: {},
      errors_create: {},
      id: null,
      requester: null,
      material: null,
      date: null,
      date_bulk: null,
      valid_excel: false,
      value: null,
      unit: null,
      vendor: null,
      currency: null,
      currency_create: null,
      material_create: null,
      material_create_code: null,
      date_create: null,
      value_create: null,
      unit_create: null,
      vendor_create: null,
      date_first: null,
      date_second: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "balance_date",
      sortOrder: "desc",
      columns: [
        {
          dataField: "balance_date",
          text: "Balance Month",
          formatter: this.GetDateFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "material_name",
          text: "Material",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "balance",
          text: "Beginning  Balance",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "unit",
          text: "Unit",
          classes: "text-center",
          sort: true,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      defaultSorted: [
        {
          dataField: "balance_date",
          order: "desc",
        },
      ],
      cellEditProps: {},
      material_code_search: null,
      link: `${process.env.PUBLIC_URL}/bom_fg.xlsx`,
      template_cost_rates: [
        ["account", "facility", "unit", "month", "value"],
        ["P00001/SAP003", "5611-80179", "HR", "0", "0"],
      ],
    };
  }

  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    //read data currency without rp on backend
    readCurrencyWithoutRP().then((response) => {
      this.setState({ select_items: response });
    });
    readMaterialByType("ZSFG,ZFGD").then((response) => {
      this.setState({ select_items_material: response });
    });

    readUom().then((response) => {
      this.setState({ select_items_uom: response });
    });

    const { sortBy, sortOrder, page, sizePerPage } = this.state;
    const date_first = subMonths(new Date(), 5);
    const date_second = new Date();
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    const query_string = `date_first=${date_first_formatted}&date_second=${date_second_formatted}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    readQsBeginningFgBalance(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });

    this.setState({
      date_create: new Date(),
      date_first: subMonths(new Date(), 5),
      date_second: new Date(),
      requester: user.username,
    });
    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
        isAdmin: check_access.isAdmin,
      });
    }
  }
  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  //change state date
  handleDate = (name, date) => {
    this.setState({
      [name]: date,
    });
    if (name == "date_bulk") {
      this.upload_excel.value = null;
      this.setState({
        worksheet: [],
        excel: null,
        valid_excel: false,
      });
    }
  };
  //check diff date on day
  diff_date_on_day = (date1, date2) => {
    let diff_on_second = new Date(date2).getTime() - new Date(date1).getTime();
    return diff_on_second / (1000 * 60 * 60 * 24);
  };

  //check diff month on day
  //date format on datatable
  GetDateFormat = (cell, row) => {
    const date_formatted = dateFormat(new Date(row.balance_date), "mmmm yyyy");
    return <div>{date_formatted}</div>;
  };
  //action button
  GetActionFormat = (cell, row) => {
    let is_noneditable = true;
    let date_db = new Date(row.balance_date);
    let currencyreadOnly = true;
    const { isEdit } = this.state;
    return isEdit ? (
      <div>
        <button
          key={row._id}
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => {
            this.toDetail(
              row._id,
              row.balance,
              row.material_name,
              row.material_code,
              row.unit,
              date_db,
              is_noneditable,
              currencyreadOnly
            );
          }}
        >
          <i className="fas fa-edit" key={row._id}></i>
          &nbsp;Edit
        </button>
      </div>
    ) : (
      ""
    );
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    return <div>{thousandformat(isNaN(cell) ? "" : cell, ".", ".", ",")}</div>;
  };
  //handle change
  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.label,
        [name + "_code"]: newValue.value,
      });
      if (name == "material") {
        this.setState({
          isValidMaterial: true,
        });
      } else if (name == "material_create") {
        readMaterialUomByCode(newValue.value).then((response) => {
          this.refUnit.value = response[0].value;
          this.setState({
            unit_create: response[0].value,
            isValidMaterialCreate: true,
          });
        });
      }
    } else {
      this.setState({
        [name]: null,
        [name + "_code"]: null,
      });
      if (name == "material") {
        this.setState({
          isValidMaterial: false,
        });
      } else if (name == "material_create") {
        this.refUnit.value = null;
        this.setState({
          isValidMaterialCreate: false,
          unit_create: null,
        });
      }
    }
  };
  //trigger modal237:24
  toDetail = (id, value, material, material_code, unit, date) => {
    this.setState(
      {
        id: id,
        value: value,
        unit: unit,
        date: date,
        submitted: false,
        material: material,
        material_code: material_code,
        isValidMaterial: true,
      },
      () => {
        this.toggle();
      }
    );
  };

  onChangeHandlerFile = (event) => {
    if (!this.state.date_bulk) {
      alert("input balance month first then upload again!");
      event.target.value = null;
      return false;
    }
    let errors = this.state.errors;
    errors.filename = null;
    this.setState({
      selectedFile: event.target.files,
      loading: true,
      worksheet_name: true,
      error_message_filename: null,
      errors: errors,
    });
    const files = event.target.files;
    if (files && files[0]) {
      this.handleFile(files[0]);
      let errors = this.state.errors;
      errors.worksheet = null;
      this.setState({
        errors: errors,
      });
    }
    this.setState({
      loading: false,
    });
  };
  handleFile = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      let worksheet = [];
      wb.SheetNames.map((e, i) => {
        const wsname = wb.SheetNames[i];
        const result_worksheet = this.setting_worksheet(
          wb.Sheets[wsname],
          wsname
        );
        if (result_worksheet) worksheet.push(result_worksheet);
      });

      const user = JSON.parse(localStorage.getItem("user"));
      let department =
        user.details.hris_org_tree.current_person.nama_department;
      const { requester, date_bulk } = this.state;
      // filter selected material only
      let rows = worksheet[0]["data"];
      let cols = worksheet[0]["cols"];
      let wrong_format = true;
      let header_name = [
        "Material",
        "Material Description",
        "To Date",
        "          Closing Stock",
        "BUn",
      ];
      let format_columns = [
        { index: 0, type: "string" },
        { index: 1, type: "string" },
        { index: 2, type: "date" },
        { index: 3, type: "int" },
        { index: 4, type: "string" },
      ];

      for (const c of cols) {
        if (c.name != header_name[c.key]) {
          wrong_format = false;
          break;
        }
      }
      // check count and header names
      if (!wrong_format || cols.length != header_name.length) {
        alert("wrong format file");
        this.upload_excel.value = null;
        this.setState({
          worksheet: null,
          valid_excel: false,
        });
        return false;
      }
      let filtered = rows.filter((rows) => {
        var to_date = rows[2].split(".");
        const monthCheck = moment(`${to_date[2]}-${to_date[1]}-${to_date[0]}`)
          .add(1, "months")
          .format("MM");
        // check apakah to_date + 1
        return monthCheck == date_bulk.getMonth() + 1;
      });

      let int_columns = format_columns.filter(function (value) {
        return value.type == "int";
      });

      let value_wrong_format = false;
      // build collection to send
      const formatted_data = filtered.map((rows) => {
        // check if number
        for (const ic of int_columns) {
          if (isNaN(rows[ic.index])) {
            value_wrong_format = true;
            return;
          }
        }

        var to_date = rows[2].split(".");
        var dt = new Date(to_date[2], to_date[1] - 1, to_date[0]);

        //check if date
        if (!dt instanceof Date) {
          value_wrong_format = true;
          return;
        }

        dt.setDate(1);
        dt.setMonth(dt.getMonth() + 1);

        let date_formatted = dateFormat(new Date(dt), "mmmm yyyy");

        let balance = rows[3];
        let material = rows[1];
        let material_code = rows[0];
        let unit = rows[4];

        let formatted = {
          requester: requester,
          balance: balance,
          material_name: material,
          material_code: material_code,
          unit: unit,
          balance_date: date_formatted,
          department: department,
        };

        return formatted;
      });
      if (value_wrong_format) {
        alert("wrong value format");
        this.upload_excel.value = null;
        this.setState({
          worksheet: null,
          valid_excel: false,
        });
        return;
      }

      worksheet[0]["data"] = filtered;

      const excel = {
        worksheet: worksheet,
        filename: file.name,
      };
      this.setState({
        worksheet: worksheet,
        loading: false,
        excel: excel,
        data_bulk: formatted_data,
      });
      if (formatted_data.length > 0) {
        this.setState({
          valid_excel: true,
        });
      }
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  exists_only = (data, cols, start_row = 0, allowed_cols = []) => {
    let header = [];
    let detail = [];
    try {
      let clone_data = [...data];

      if (clone_data) {
        clone_data.map((r, i) => {
          let z = [...r];
          let splice = z.splice(0, 2);
          if (i === start_row) {
            let header_concat = splice.concat(z);
            header.push(header_concat);
          }
          //
          let cleanArray = z.filter(function (el) {
            let element = el.toString();
            return element != null && element != "";
          });

          if (i !== start_row && cleanArray.length > 2) {
            let array_concat = splice.concat(z);
            detail.push(array_concat);
          }
        });
      }
      return { header: header, detail: detail };
    } catch (err) {
      return { header: header, detail: detail };
    }
  };

  //setting worksheet
  setting_worksheet = (ws, wsname) => {
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    if (!data) return;
    /* Update state */
    if (!ws["!ref"]) return;
    const cols = this.make_cols(ws["!ref"]);
    const result = this.exists_only(data, cols);
    const custom_cols = this.make_custom_cols(result.header);

    return {
      worksheet: wsname,
      data: result.detail,
      cols: custom_cols,
    };
  };

  /* generate an array of column objects */
  make_cols = (refstr) => {
    let o = [],
      C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i)
      o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
  };
  make_custom_cols = (obj) => {
    let o = [];
    if (obj) {
      obj[0].map((b, d) => {
        o.push({ name: b, key: d });
      });
    }
    return o;
  };

  //toggle modal
  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      material: null,
      value: null,
      unit: null,
      date: null,
      showModal: !this.state.showModal,
      readOnly: false,
    });
  };
  //validate input
  validateForm = (material, date, value, unit) => {
    let errors = this.state.errors;
    if (!material) {
      errors.material = "This is field required";
      this.setState({
        isValidMaterial: false,
      });
    }
    if (!date) {
      errors.date = "This is field required";
    }
    if (!value) {
      errors.value = "This is field required";
    } else if (value < 0) {
      errors.value = "Value cannot below 0";
    }
    if (!unit) {
      errors.unit = "This is field required";
    }
    return errors;
  };
  validateFormCreate = (material, date, value, unit) => {
    let errors = this.state.errors_create;
    if (!material) {
      errors.material = "This is field required";
      this.setState({
        isValidMaterialCreate: false,
      });
    }
    if (!date) {
      errors.date = "This is field required";
    }
    if (!value) {
      errors.value = "This is field required";
    } else if (value < 0) {
      errors.value = "Value cannot below 0";
    }
    if (!unit) {
      errors.unit = "This is field required";
    }
    return errors;
  };
  //get data from backend
  reloadDataBackend = () => {
    const {
      date_first,
      date_second,
      page,
      sizePerPage,
      sortBy,
      sortOrder,
      material_code_search,
    } = this.state;
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    const query_string = `date_first=${date_first_formatted}&date_second=${date_second_formatted}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}&no_material=${material_code_search}`;

    this.form.reset();
    this.refMaterial.state.value.value = "";
    this.refMaterial.state.value.label = "";
    readQsBeginningFgBalance(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.foundData ? response.countData : 0,
        page: response.foundData ? response.currentPage : 1,
        submitted_create: false,
        material_create: null,
        material_create_code: null,
        unit_create: null,
        value_create: null,
        date_create: new Date(),
      });
    });
    readMaterialByType("ZSFG,ZFGD").then((response) => {
      this.setState({ select_items_material: response });
    });
  };
  //handletable
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (page == 0) {
      page = 1;
    }
    this.setState({
      sizePerPage: sizePerPage,
      page: page,
      sortBy: sortField,
      sortOrder: sortOrder,
    });
    const { date_first, date_second, material_code_search } = this.state;
    const operator = {
      "=": "eq",
      ">": "gt",
      ">=": "gte",
      "<": "lt",
      "<=": "lte",
      "!=": "ne",
    };
    const columnField = {
      material: "material_name",
      vendor: "vendor_name",
      spot_price: "spot_price",
      unit: "unit",
    };
    let filterColumn = false;
    let array_filter = [];
    for (const dataField in filters) {
      const { filterVal, comparator, filterType } = filters[dataField];
      filterColumn = true;
      if (filterType === "SELECT") {
        if (filterVal) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal}&${columnField[dataField]}_operator=${operator[comparator]}`
          );
        }
      }
      if (filterType === "NUMBER") {
        if (filterVal.number) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal.number}&${
              columnField[dataField]
            }_operator=${operator[filterVal.comparator]}`
          );
        }
      }
    }
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    let query_string = `date_first=${date_first_formatted}&date_second=${date_second_formatted}&sortBy=${sortField}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}&no_material=${material_code_search}`;
    if (filterColumn) {
      query_string += "&" + array_filter.join("&");
    }
    readQsBeginningFgBalance(query_string).then((response) => {
      this.setState({
        list_datatable: response ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
  };
  //submit form
  handleSubmit = (e) => {
    this.setState({ submitted_create: true });
    const { history } = this.props;
    const {
      requester,
      value_create,
      material_create,
      material_create_code,
      unit_create,
      date_create,
    } = this.state;
    if (
      !this.validateFormCreate(
        material_create,
        date_create,
        value_create,
        unit_create
      )
    ) {
      return;
    }

    //get department
    const user = JSON.parse(localStorage.getItem("user"));
    let department = user.details.hris_org_tree.current_person.nama_department;

    let date_formatted = dateFormat(date_create, "mmmm yyyy");
    let collection = {
      requester: requester,
      balance: value_create,
      material_name: material_create,
      material_code: material_create_code,
      unit: unit_create,
      balance_date: date_formatted,
      department: department,
    };
    if (
      requester &&
      value_create &&
      material_create &&
      unit_create &&
      value_create >= 0
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          addBeginningFgBalance(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-exchange-rate-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
                history.push("/production/beginning-fg-balance");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-exchange-rate-failed",
              });
              if (
                err ===
                "data is already exist, Are you sure to overwrite data ?"
              ) {
                Swal.fire({
                  title: "Are you sure ?",
                  text: `to overwrite data for ${date_formatted} ${material_create} !`,
                  icon: "info",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                }).then((result) => {
                  if (result.value) {
                    overwriteBeginningFgBalance(collection)
                      .then((response) => {
                        toast.success("Data has been updated successfully", {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-update-exchange-rate-success",
                        });
                        Swal.fire({
                          title: "Information",
                          icon: "success",
                          text: "Data has been updated successfully",
                          showConfirmButton: false,
                          timer: 2000,
                        }).then((result) => {
                          this.reloadDataBackend();
                          history.push("/production/beginning-fg-balance");
                        });
                      })
                      .catch((err) => {
                        toast.error(err, {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-update-exchange-rate-failed",
                        });
                        this.setState({
                          loading: false,
                        });
                      });
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire("Cancelled", "Your data is safe :)", "error");
                  }
                });
              }
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleSubmitBulk = (e) => {
    this.setState({ submitted: true });
    const {
      requester,
      department,
      excel,
      periode,
      selectedFile,
      worksheet,
      errors,
      date_bulk,
      data_bulk,
    } = this.state;
    let result_validate = this.validateForm(selectedFile, periode, worksheet);
    if (!result_validate) {
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "Please check your entries !",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        let collection = {
          date_bulk,
          data_bulk,
          requester: requester,
          department: department,
        };

        // check initial size
        const size = new TextEncoder().encode(
          JSON.stringify(collection)
        ).length;
        const kiloBytes = size / 1024;

        let data_bulk_to_be_send = data_bulk;
        if (kiloBytes > 200) {
          const threePartIndex = Math.ceil(data_bulk.length / 3);
          const thirdPart = data_bulk.splice(-threePartIndex);
          const secondPart = data_bulk.splice(-threePartIndex);
          const firstPart = data_bulk;

          // check halfed size
          const size = new TextEncoder().encode(
            JSON.stringify(collection)
          ).length;
          const kiloBytes = size / 1024;

          if (kiloBytes > 200) {
            this.setState({
              date_bulk: null,
              worksheet: [],
              excel: null,
            });
            alert("Data is too large, please make it smaller.");
          }

          // first send
          collection.data_bulk = firstPart;
          addBeginningFgBalanceBulk(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-bom-powder-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.setState({
                  date_bulk: null,
                  worksheet: [],
                  excel: null,
                  valid_excel: false,
                });
                this.upload_excel.value = null;
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-bom-powder-failed",
              });
              this.setState({
                loading: false,
              });
            });

          // second send
          collection.data_bulk = secondPart;
          addBeginningFgBalanceBulk(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-bom-powder-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.setState({
                  date_bulk: null,
                  worksheet: [],
                  excel: null,
                  valid_excel: false,
                });
                this.upload_excel.value = null;
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-bom-powder-failed",
              });
              this.setState({
                loading: false,
              });
            });

          collection.data_bulk = thirdPart;
        }
        // last send
        addBeginningFgBalanceBulk(collection)
          .then((response) => {
            toast.success("Data has been saved successfully", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-add-bom-powder-success",
            });
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "Data has been saved successfully",
              showConfirmButton: false,
              timer: 2000,
            }).then((result) => {
              this.reloadDataBackend();
              this.setState({
                date_bulk: null,
                worksheet: [],
                excel: null,
                valid_excel: false,
              });
              this.upload_excel.value = null;
            });
          })
          .catch((err) => {
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-add-bom-powder-failed",
            });
            this.setState({
              loading: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };
  //handle submit edit
  handleSubmitEdit = () => {
    this.setState({ submitted: true });

    //get department
    const user = JSON.parse(localStorage.getItem("user"));
    let department = user.details.hris_org_tree.current_person.nama_department;

    const { id, material, unit, value, date, requester } = this.state;

    if (!this.validateForm(material, date, value, unit)) {
      return;
    }
    const { history } = this.props;
    let collection = {
      _id: id,
      balance: value ? value.toString() : "",
      department: department,
      requester: requester,
    };
    if (id && material && unit && value && date && value >= 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, update entries",
      }).then((result) => {
        if (result.value) {
          //TODO
          editBeginningFgBalance(collection)
            .then((response) => {
              this.toggle();
              this.setState({
                submitted: false,
              });
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-update-exchange-rate-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
                history.push("/production/beginning-fg-balance");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-update-exchange-rate-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  //handle search
  handleSearch = () => {
    const {
      date_first,
      date_second,
      sortBy,
      sortOrder,
      page,
      sizePerPage,
      material_code_search,
    } = this.state;
    if (date_first > date_second) {
      this.setState({
        date_second: new Date(),
        date_first: subMonths(new Date(), 6),
      });
      return Swal.fire({
        title: "Warning",
        icon: "warning",
        text: "Please select date properly!",
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    let query_string = `date_first=${date_first_formatted}&date_second=${date_second_formatted}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}&no_material=${material_code_search}`;
    readQsBeginningFgBalance(query_string).then((response) => {
      this.setState(
        {
          list_datatable: response.foundData ? response.foundData : [],
          totalSize: response.foundData ? response.countData : 0,
          page: response.foundData ? response.currentPage : 1,
        },
        () => {
          if (response.foundData.length === 0) {
            query_string = `date_first=${date_first_formatted}&date_second=${date_second_formatted}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=1&sizePerPage=${sizePerPage}&no_material=${material_code_search}`;
            readQsBeginningFgBalance(query_string).then((res) => {
              this.setState({
                list_datatable: res.foundData ? res.foundData : [],
                totalSize: res.foundData ? res.countData : 0,
                page: res.foundData ? res.currentPage : 1,
              });
            });
          }
        }
      );
    });
  };
  render() {
    let {
      columns,
      defaultSorted,
      showModal,
      cellEditProps,
      select_items,
      select_items_material,
      select_items_uom,
      error_message_filename,
      list_datatable,
      id,
      currency,
      worksheet,
      material,
      vendor,
      unit,
      value,
      date,
      date_bulk,
      valid_excel,
      currency_create,
      material_create,
      vendor_create,
      unit_create,
      value_create,
      date_create,
      date_first,
      date_second,
      errors,
      errors_create,
      submitted_create,
      submitted,
      currencyreadOnly,
      loading,
      isValidMaterial,
      isValidMaterialCreate,
      isValidVendor,
      isValidVendorCreate,
      isClearable,
      isCreate,
      isRead,
      page,
      sizePerPage,
      totalSize,
      material_code_search,
      isAdmin,
    } = this.state;

    const customStylesMaterial = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterial
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterial
            ? "#ddd"
            : "green",
        },
      }),
    };

    const customStylesVendor = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? "#ddd" : isValidVendor ? "#ddd" : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidVendor
            ? "#ddd"
            : "green",
        },
      }),
    };

    const customStylesMaterialCreate = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterialCreate
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterialCreate
            ? "#ddd"
            : "green",
        },
      }),
    };
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              noValidate
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>
                  {id ? "Update" : "Create"} Beginning FG Balance
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={4}>
                      <div
                        className={
                          errors.material != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="material">Material *</label>
                        <Select
                          isDisabled
                          styles={customStylesMaterial}
                          onChange={this.handleChange.bind(this, "material")}
                          options={select_items_material}
                          defaultValue={select_items_material.filter(
                            (e) => e.label === material
                          )}
                          isClearable={isClearable}
                        />

                        {!isValidMaterial && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: " #dc3545",
                            }}
                          >
                            {errors.material}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="date">Balance Month *</label>
                        <DatePicker
                          selected={date}
                          startDate={date}
                          maxDate={
                            date
                              ? addMonths(new Date(date), 0) !==
                                addMonths(new Date(), 0)
                                ? addMonths(new Date(), 0)
                                : addMonths(new Date(), 0)
                              : addMonths(new Date(), 0)
                          }
                          minDate={
                            date
                              ? startOfMonth(new Date(date)) !==
                                startOfMonth(new Date())
                                ? startOfMonth(new Date())
                                : startOfMonth(new Date())
                              : startOfMonth(new Date())
                          }
                          disabledKeyboardNavigation
                          onChange={this.handleDate.bind(this, "date")}
                          name="date"
                          wrapperClassName="datePickerCustom"
                          dateFormat="MMMM yyyy"
                          className="form-control form-control-sm"
                          required
                          showMonthYearPicker
                          disabled
                        />
                        {errors.date && (
                          <div className="invalid-feedback">{errors.date}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="value">Beginning Value *</label>
                        <input
                          type="number"
                          step="0.001"
                          className="form-control form-control-sm"
                          name="value"
                          placeholder="Value"
                          required="required"
                          value={value || ""}
                          onChange={this.onChangeValue}
                          min="0"
                        />
                        {errors.value && (
                          <div className="invalid-feedback">{errors.value}</div>
                        )}
                      </div>
                      <div
                        className={
                          errors.unit != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="unit">Unit *</label>

                        <select
                          className={
                            errors.unit != "This field is required"
                              ? "form-control form-control-sm "
                              : "form-control form-control-sm  has-error has-feedback"
                          }
                          name="unit"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={unit}
                          readOnly={currencyreadOnly}
                          required
                          disabled
                        >
                          <option value="">Choose</option>
                          {select_items_uom &&
                            select_items_uom.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.unit && (
                          <div className="invalid-feedback">{errors.unit}</div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                {id && (
                  <Button
                    variant="success"
                    onClick={this.handleSubmitEdit}
                    className="float-right"
                  >
                    <i className="fas fa-save" /> Update
                  </Button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Beginning FG Balance</h3>
                        </div>
                        <Card.Body className="card-body">
                          <Tabs
                            defaultActiveKey="bulk"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                          >
                            <Tab eventKey="bulk" title="Bulk Input">
                              <form
                                autoComplete="off"
                                className={
                                  submitted_create
                                    ? "needs-validation was-validated"
                                    : "needs-validation"
                                }
                                noValidate
                                ref={(form) => (this.form = form)}
                              >
                                <Row>
                                  <Col xs={12} md={4}>
                                    <div className="form-group row">
                                      <div className="col-md-4 col-xs-12">
                                        <label htmlFor="month">
                                          Balance Month *
                                        </label>
                                      </div>
                                      <div className="col-md-8 col-xs-12">
                                        <div className="form-group">
                                          <DatePicker
                                            selected={date_bulk}
                                            disabledKeyboardNavigation
                                            onChange={this.handleDate.bind(
                                              this,
                                              "date_bulk"
                                            )}
                                            name="date_bulk"
                                            wrapperClassName="datePickerCustom"
                                            dateFormat="MMMM yyyy"
                                            className="form-control form-control-sm"
                                            required
                                            showMonthYearPicker
                                          />
                                          {errors.date_bulk && (
                                            <div className="invalid-feedback">
                                              {errors.date_bulk}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <div className="col-md-4 col-xs-12">
                                        <label htmlFor="file">
                                          Choose File (Excel) *
                                        </label>
                                      </div>
                                      <div className="col-md-8 col-xs-12">
                                        <input
                                          type="file"
                                          className="form-control form-control-sm"
                                          name="file"
                                          placeholder="File"
                                          onChange={this.onChangeHandlerFile}
                                          accept={SheetJSFT}
                                          required
                                          ref={(ref) =>
                                            (this.upload_excel = ref)
                                          }
                                        />

                                        {errors.file && (
                                          <div className="invalid-feedback">
                                            {errors.file}
                                          </div>
                                        )}

                                        {error_message_filename && (
                                          <em
                                            className="error-message"
                                            style={{
                                              fontSize: "80%",
                                            }}
                                          >
                                            {error_message_filename}
                                          </em>
                                        )}

                                        {errors.worksheet && (
                                          <em
                                            className="error-message"
                                            style={{
                                              fontSize: "80%",
                                            }}
                                          >
                                            {errors.worksheet}
                                          </em>
                                        )}
                                        {errors.filename && (
                                          <em
                                            className="error-message"
                                            style={{
                                              fontSize: "80%",
                                            }}
                                          >
                                            {errors.filename}
                                          </em>
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col xs={12} md={8}>
                                    <a
                                      href={`${process.env.PUBLIC_URL}/FormatExcelFGBalance.xlsx`}
                                      download="FormatExcelFGBalance.xlsx"
                                    >
                                      <Button
                                        variant="primary"
                                        className="float-right"
                                      >
                                        <i className="fas fa-download" />{" "}
                                        Template Excel
                                      </Button>
                                    </a>
                                  </Col>
                                </Row>
                                {/* row preview */}
                                <Row>
                                  <Col xs={12} md={12}>
                                    <Worksheet worksheet={worksheet} />
                                  </Col>
                                </Row>
                                <br />
                                <Row>
                                  <Col xs={12} md={12}>
                                    <Button
                                      variant="success"
                                      onClick={this.handleSubmitBulk}
                                      className="float-right  "
                                      disabled={!valid_excel}
                                    >
                                      <i className="fas fa-save" /> Submit
                                    </Button>
                                  </Col>
                                </Row>
                              </form>
                            </Tab>
                            <Tab eventKey="single" title="Single Input">
                              <form
                                autoComplete="off"
                                className={
                                  submitted_create
                                    ? "needs-validation was-validated"
                                    : "needs-validation"
                                }
                                noValidate
                                ref={(form) => (this.form = form)}
                              >
                                <Row>
                                  <Col xs={12} md={4}>
                                    <div
                                      className={
                                        errors_create.material !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="material">
                                        Material *
                                      </label>
                                      <Select
                                        styles={customStylesMaterialCreate}
                                        onChange={this.handleChange.bind(
                                          this,
                                          "material_create"
                                        )}
                                        options={select_items_material}
                                        defaultValue={select_items_material.filter(
                                          (e) => e.value === material_create
                                        )}
                                        isClearable={isClearable}
                                        ref={(ref) => (this.refMaterial = ref)}
                                      />

                                      {!isValidMaterialCreate && (
                                        <div
                                          style={{
                                            width: "100%",
                                            marginTop: ".25rem",
                                            fontSize: "80%",
                                            color: " #dc3545",
                                          }}
                                        >
                                          {errors_create.material}
                                        </div>
                                      )}
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="date">
                                        Balance Month *
                                      </label>
                                      <DatePicker
                                        selected={date_create}
                                        startDate={date_create}
                                        maxDate={
                                          !isAdmin
                                            ? date_create && !isAdmin
                                              ? addMonths(
                                                  new Date(date_create),
                                                  0
                                                ) !== addMonths(new Date(), 0)
                                                ? addMonths(new Date(), 0)
                                                : addMonths(new Date(), 0)
                                              : addMonths(new Date(), 0)
                                            : addMonths(new Date(), 12)
                                        }
                                        minDate={
                                          !isAdmin
                                            ? date_create && !isAdmin
                                              ? startOfMonth(
                                                  new Date(date_create)
                                                ) !== startOfMonth(new Date())
                                                ? startOfMonth(new Date())
                                                : startOfMonth(new Date())
                                              : startOfMonth(new Date())
                                            : subMonths(new Date(), 12)
                                        }
                                        disabledKeyboardNavigation
                                        onChange={this.handleDate.bind(
                                          this,
                                          "date_create"
                                        )}
                                        name="date"
                                        wrapperClassName="datePickerCustom"
                                        dateFormat="MMMM yyyy"
                                        className="form-control form-control-sm"
                                        required
                                        showMonthYearPicker
                                        disabled={isAdmin ? false : true}
                                      />
                                      {errors_create.date && (
                                        <div className="invalid-feedback">
                                          {errors_create.date}
                                        </div>
                                      )}
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="opening_rate">
                                        Beginning Value *
                                      </label>
                                      <input
                                        type="number"
                                        step="0.001"
                                        className="form-control form-control-sm"
                                        name="value_create"
                                        placeholder="value"
                                        required="required"
                                        value={value_create || ""}
                                        onChange={this.onChangeValue}
                                        min="0"
                                      />
                                      {errors_create.value && (
                                        <div className="invalid-feedback">
                                          {errors_create.value}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        errors_create.unit !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="unit">Unit *</label>

                                      <select
                                        className={
                                          errors_create.unit !=
                                          "This field is required"
                                            ? "form-control form-control-sm "
                                            : "form-control form-control-sm  has-error has-feedback"
                                        }
                                        name="unit_create"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={unit_create}
                                        readOnly={true}
                                        required
                                        ref={(ref) => (this.refUnit = ref)}
                                      >
                                        <option value="">Choose</option>
                                        {select_items_uom &&
                                          select_items_uom.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors_create.unit && (
                                        <div className="invalid-feedback">
                                          {errors_create.unit}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12}>
                                    <Button
                                      variant="success"
                                      onClick={this.handleSubmit}
                                      className="float-right"
                                    >
                                      <i className="fas fa-save" /> Submit
                                    </Button>
                                  </Col>
                                </Row>
                              </form>
                            </Tab>
                          </Tabs>
                        </Card.Body>
                      </div>
                    </div>
                  </div>
                )}
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Historical Data</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <form autoComplete="off">
                            <Row>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="date">Start Month</label>
                                  <DatePicker
                                    selected={date_first}
                                    startDate={date_first}
                                    disabledKeyboardNavigation
                                    onChange={this.handleDate.bind(
                                      this,
                                      "date_first"
                                    )}
                                    name="date"
                                    dateFormat="MMMM yyyy"
                                    className="form-control form-control-sm"
                                    showMonthYearPicker
                                    required
                                  />
                                </div>
                              </Col>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="date">End Month</label>
                                  <DatePicker
                                    selected={date_second}
                                    startDate={date_second}
                                    disabledKeyboardNavigation
                                    onChange={this.handleDate.bind(
                                      this,
                                      "date_second"
                                    )}
                                    name="date"
                                    dateFormat="MMMM yyyy"
                                    className="form-control form-control-sm"
                                    showMonthYearPicker
                                    required
                                  />
                                </div>
                              </Col>
                              <Col xs={12} md={2}>
                                <div className="form-row form-group">
                                  <label htmlFor="material_code_search"></label>

                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="material_code_search"
                                    placeholder="Material Code"
                                    required="required"
                                    value={material_code_search || ""}
                                    onChange={this.onChangeValue}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <Button
                                  variant="info"
                                  onClick={this.handleSearch}
                                  className="float-left"
                                >
                                  <i className="fas fa-search" /> Search
                                </Button>
                              </Col>
                            </Row>
                          </form>
                          <br />
                          <RemoteAll
                            data={list_datatable}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            cellEditProps={cellEditProps}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            onTableChange={this.handleTableChange.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default withRouter(BeginningFgBalance);
